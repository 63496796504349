/* General Styles */
.section {
  padding: 15px;
  margin: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
}

/* Reverse Layout for Alternating Sections */
.section.reverse {
  flex-direction: row-reverse;
}

/* Image Container */
.image-container {
  flex: 1;
  max-width: 40%; /* Adjust as needed */
  padding: 10px;
}

/* About Image */
.about-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Content Container */
.content-container {
  flex: 2;
  max-width: 55%; /* Adjust as needed */
  padding: 10px;
}

/* Text Styles */
.content-container h1 {
  text-align: left;
  color: #0d367e;
  text-transform: capitalize;
  font-size: clamp(60px, 2vw + 1rem, 90px);
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: calc(0.8 * clamp(50px, 2vw + 1rem, 65px));
}

.content-container h3 {
  font-family: Garamond;
  text-transform: none;
  font-weight: 400;
  font-size: clamp(14px, 5vw, 20px);
  line-height: clamp(1.5, 1.6, 1.8);
  text-align: left;
  color: black;
  padding-bottom: 5px;
}

.content-container p {
  padding-bottom: 5px;
  font-family: Garamond;
  text-transform: none;
  font-weight: 400;
  font-size: clamp(14px, 5vw, 20px);
  line-height: clamp(1.5, 1.6, 1.8);
  text-align: left;
  margin-bottom: calc(0.8 * clamp(50px, 2vw + 1rem, 65px));
}

.content-container h4 {
  text-align: left;
  color: #0d367e;
  font-size: clamp(35px, 2vw + 1rem, 45px);
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: calc(1 * clamp(10px, 2vw + 1rem, 25px));
}

.content-container ul {
  list-style-type: none;
}

.content-container ul li {
  padding-bottom: 5px;
  font-family: Garamond;
  text-transform: none;
  font-weight: 400;
  font-size: clamp(14px, 5vw, 20px);
  line-height: clamp(1.5, 1.6, 1.8);
  text-align: left;
  margin-bottom: calc(0.8 * clamp(50px, 2vw + 1rem, 65px));
}

.content-container ul li::before {
  content: "➤";
  padding-right: 10px;
  color: #0083ca;
}

/* Responsive Design */
@media screen and (max-width: 1150px) {
  .section {
    flex-direction: column;
    align-items: center;
  }

  .section.reverse {
    flex-direction: column;
  }

  .image-container {
    max-width: 100%;
    order: 0 !important; /* Reset order for mobile */
  }

  .content-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .section {
    margin: 5px 15px;
  }

  .content-container h1,
  .content-container h4 {
    text-align: center;
  }

  .content-container h3 {
    font-size: 19px;
  }

  .content-container ul li {
    font-size: 20px;
  }
}

@media screen and (max-width: 450px) {
  .content-container p {
    font-size: 18px;
  }
}

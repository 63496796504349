.projects {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}
.tdBefore {
  display: none;
}
Th {
  border-bottom: 1px solid black;
  text-align: center;
  font-size: 1.5rem !important;
  color: rgb(51, 5, 237) !important;
  font-family: garamond;
}

Td,
strong,
Tr {
  text-align: left !important;
  font-family: var(--font-base);
  font-size: 22px;
  color: #000;
  font-family: garamond;
  padding: 10px 5px;
  font-weight: 400;
}
strong {
  text-transform: none;
  font-family: garamond;
  color: #000;
  font-weight: 600;
}
caption {
  font-size: 2rem;
  font-weight: 600;
  font-family: garamond;
  color: #0083ca;
}
Table {
  margin: 15px 10px 30px 15px;
  border: 0px dotted #001;
}

.footer {
  padding: 15px 5px;
}
.footer p {
  padding: 5px 15px;
  font-size: 20px;
  color: #0083ca;
  text-align: center;
  font-weight: 600;
}

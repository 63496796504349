.contact {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
}
.house {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  margin: 2px;
}
.house > img {
  object-fit: cover;
  width: 100%;
}
.number {
  padding: 3px 1px;
  flex: 0.3;
  align-items: center;
  line-height: 1.4;
}
.number h3 {
  font-size: 20px;
  font-family: var(--font-base);
  padding: 10px;
  align-items: left;
  font-weight: 500;
  color: #000;
}
.number p {
  font-size: 20px;
  font-family: dosis;
  padding: 10px 5px;
  align-items: center;
  font-weight: 540;
}
.hour {
  padding: 3px 1px;
  flex: 0.3;
  line-height: 1.4;
}
.hour h2 {
  font-size: 21px;
  text-align: center;
  margin: 10px;
}
.hour p {
  font-size: 20px;
  padding: 10px;
}
.form-item {
  padding: 15px 1px;
  text-align: center;
  display: flex;
  flex: 0.4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  text-align: center;
  margin: 10px;
  align-items: center;
  height: 40px;
  display: flex;
  flex-direction: column;
  width: 300px;
  font-size: 18px;
  font-family: garamond;
  border-radius: 15px;
}
label {
  text-align: center;
  font-weight: bold;
}
textarea {
  border-radius: 15px;
  resize: none;
  width: 300px;
  font-size: 18px;
  font-family: garamond;
  align-items: center;
  height: 150px;
  text-align: center;
  padding: 30px 5px 0 5px;
}
.btn {
  padding: 10px 20px;
  color: #000;
  background-color: #0083ca;
  width: 100%;
  align-items: center;
  border-radius: 15px;
  border: none;
}
.btn:hover {
  padding: 10px 20px;
  color: blue;
  background-color: #25d366;
  width: 100%;
  text-align: center;
  border-radius: 20px;
}
p {
  padding: 5px;
  font-size: 20px;
  font-family: garamond;
}
@media screen and (min-width: 2000px) {
  .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (max-width: 1150px) {
  .contact {
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
  }
  .form-item {
    padding: 20px 1px;
    text-align: center;
    flex-direction: column;
    height: auto;
  }
  .btn {
    color: #000;
  }
}
@media screen and (max-width: 768px) {
  .contact {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .form-item {
    padding: 2px 1px;
    text-align: center;
    flex-direction: column;
  }
  .btn {
    color: #000;
    padding: 5px 3px 3px 5px;
  }
}

.container {
  .myswiper {
    width: 100% !important;
    height: 100%;
    object-fit: fill !important;
    text-align: center !important;
    .swiperslide {
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      img {
        width: 100% !important;
        height: 520px !important;
        object-fit: cover !important;
        margin-left: 7px;
        margin-right: 7px;
      }
      span {
        font-size: 17px !important;
        margin: 30px 10px !important;
        color: rgb(255, 215, 0.1);
        text-transform: capitalize;
        font-weight: 600;
        font-family: dosis;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
  h2 {
    color: #0083ca;
    font-size: 2.5rem;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
  }
}

@media (max-width: 768px) {
  .container {
    .myswiper {
      .swiperslide {
        img {
          max-width: 100% !important;
          height: 500px !important;
          text-align: center !important;
        }
        span {
          font-size: 15px !important;
          padding-top: 5px !important;
        }
      }
    }
    h2 {
      font-size: 1.7rem;
      padding-bottom: 15px;
      text-transform: uppercase;
    }
  }
  h2 {
    font-size: 2rem;
    padding-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .container {
    padding-bottom: 20px;
    .myswiper {
      .swiperslide {
        img {
          max-width: 100% !important;
          height: 320px !important;
        }
      }
      span {
        font-size: 13px !important;
        padding: 0px !important;
      }
    }
  }
  h2 {
    font-size: 24px;
    padding-bottom: 10px;
  }
}
@media (max-width: 320px) {
  .container {
    .myswiper {
      .swiperslide {
        img {
          max-width: 100% !important;
          height: 330px !important;
        }
        span {
          font-size: 12px !important;
        }
      }
    }
  }
  h2 {
    font-size: 24px;
    padding-bottom: 12px;
  }
}

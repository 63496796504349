@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;700&family=EB+Garamond:wght@400;500;700&display=swap");
* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}
body {
  text-align: center;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  color: #000000;
  font-family: "EB Garamond", serif;
}
